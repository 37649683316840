
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter, useRoute } from "vue-router";

// Import loading overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// end import loading overlay

import Multiselect from "@vueform/multiselect";

import moment from "moment";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "History Upload Siswa",
  components: {
    Field,
    Loading, //loading overlay
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },
  
  data() {
    return {

      // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
    // end loading overlay setting

      tableData: [],
      paging: {
        page: 0,
        size: 10,
        totalPages: 0,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      items: {
        total: "",
        valid: "",
        unValid: "",
        data: [],
        historyId: "",
      },

      listSekolah: [],
      schoolId: "",

      creatorId: "",
      fileName: "",
      status: "",

      comboStatus: [
        {
          name  : "Berhasil",
          _id : "Berhasil"
        },
        {
          name : "Gagal",
          _id : "Gagal",
        }
      ],

    }
  },

  beforeMount() {
    const store = useStore();
    store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
    
    this.getListSekolah();
    this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);

    if (this.schoolId) {
      this.getData(this.paging.size, this.paging.page);
    }

  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    
    getData(size, page){

      // loading overlay tampil
      this.isLoading = true;
      const toast = useToast();
      let search2 = "";
      if (this.fileName) {
        search2 = "fileName=" + this.fileName + "&";
      } else {
        search2;
      }

      let search3 = "";
      if (this.status) {
        search3 = "status=" + this.status + "&";
      } else {
        search3;
      }

      ApiService.getWithoutSlug('crmv2/main_student/student/office/history_upload_students/' + this.schoolId + '?' + search2 + search3 + 'page=' + page + '&size=' + size + '&sort=createTime&dir=-1')
      .then(({ data }) => {
        this.tableData = data.content;
        this.paging.page = data.page;
        this.paging.size =  data.size;
        this.paging.totalElements = data.totalElements;
        this.paging.totalPages = data.totalPages;

        this.isLoading = false;
      })
      .catch((e) => {
        if (e.response.status == 401) {
          toast.error(e.response.data.detail);
          this.$router.push('/sign-in');
          this.isLoading = false;
        } else {
          toast.error(e.response.data.detail);
          this.isLoading = false;
        }
      })
    },

    modalHistoryDetail(index, item){
      const modal = new Modal(document.getElementById("kt_modal_detail_history_upload"));
      modal.show();

      this.items.historyId = item._id;

      this.apiHistoryDetail(item);

    },

    apiHistoryDetail(item){
      const toast = useToast();

      // loading overlay tampil
      this.isLoading = true;

      ApiService.getWithoutSlug('crmv2/main_student/student/office/history_upload_students/detail/' + item._id)
        .then(({ data }) => {
          this.items.total = data.totalCount;
          this.items.valid = data.validCount;
          this.items.unValid = data.unvalidCount;
          this.items.data = data.totalResult;

          this.isLoading = false;
        })
        .catch((errors) => {
          toast.error(errors.response.data.detail);
          this.isLoading = false;
        })
    },

    downloadHistory(){

      const toast = useToast();

      ApiService.getDownload(
        "crmv2/main_student/student/office/history_upload_students/detail/" +
          this.items.historyId + '/history_student.xlsx'
      ).then((response) => {
        // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          var extension = 'xlsx';
          link.setAttribute("download", "Data History Upload" + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();

      })
      .catch((error) => {
        console.log(error);
        toast.error("Gagal Unduh File");
      }); 

    },

    pilihStatusHistory(event) {
      if (event) {
        this.status = event;
      } else {
        this.status = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },

    getListSekolah() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
          this.isLoading = false;
        }
      ).catch(({ response }) => {
          this.isLoading = false;
        });
    },

    pilihSekolah(event) {
      if (event) {
        this.schoolId = event;
        localStorage.setItem("sekolahId", JSON.stringify(this.schoolId));
        this.getData(this.paging.size, this.paging.page);
      } else {
        this.schoolId = "";
        this.tableData= [];
      }
    },

    formatDate: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },

    dateTime2(value) {
      return moment(value).format("DD-MM-YYYY HH:mm:ss");
    },

    range(min, max) {
      const arr: any[] = []
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
  },
});
